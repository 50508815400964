import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../images/races-background.jpg"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <p> </p>
    <DescWrapper>
      <h1>Dead End</h1>
    <ImgWrapper>
    <div> </div>
    <Link to="/"><img src = {Image}></img></Link>
    </ImgWrapper>
    <p>So sorry, but this trail doesn&#39;t go anywhere.</p>
    </DescWrapper>
  </Layout>
)

const DescWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px;
    margin-left: 150px;
    margin-right: 150px;
    text-align: center;

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        margin-left: 20px;
        margin-right: 20px;
        
        .display-1 {
          font-size: 2.4rem;
          text-align: left;
        }
      }
`

const ImgWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    img:hover {
        box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.2), 0 16px 34px 0 rgba(0, 0, 0, 0.19);
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        margin-left: 20px;
        margin-right: 20px;
        
        .display-1 {
          font-size: 2.4rem;
          text-align: left;
        }
      }
`

export default NotFoundPage
